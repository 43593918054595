.text-box{
    background-color:#F1F1F1;
    border-radius: 10px;
    color:  #404AA7;
    font-size: 18px;
    align-items: center;
    padding-left: 6px;
}
.link-box{
    border: 1px solid #C7D3FF;
    border-radius: 10px;
    align-items: center;
    flex-direction: row;
    color:#9F9F9F;
    font-size: 17px;
    padding-left: 6px;
}
.email-box{
    border: 1px solid #C7D3FF;
    border-radius: 10px;
    color:#9A94BE;
    font-size: 17px;
    align-items:center;
    padding-left: 6px;
}
.icon{
    padding-left:2px;
}
.image-field{
    width: 100%;
}