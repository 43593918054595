.certificate-detail-intake {
  color: #444343 !important;
  font-size: 15px !important;
}

.certificate-detail-date {
  color: #726aa5;
  font-size: 15px;
}

.certificate-button {
  background-color: #9470fd !important;
}
