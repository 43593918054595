.basic-detail{
    color:#C6AEAE;
    font-size: 20px;
}
.student-detail{
    color: #9A94BE;
    font-size: 20px;
    
}
.detail-card{
    background-color:#ffffff ;
    border-radius: 20px;
    height: 100%;
    box-shadow:0 0 10px rgba(0, 0, 0, 0.1);
    position: relative; 
}
.divider{
    background-color:#E1DEDE;
    height: 2px;
}
.assign-card{
    background-color:#ffffff ;
    padding: 1%;
    border-radius: 20px;
    box-shadow:0 0 10px rgba(0, 0, 0, 0.1) ;
}
.assign-card-detail{
    height: 325px;
    overflow-y: auto;
    padding-top: 2%;
}
.certificate-card{
    background-color:#ffffff ;
    padding: 1%;
    border-radius: 20px;
    box-shadow:0 0 10px rgba(0, 0, 0, 0.1) ;
    overflow-y: auto;
    margin-top: 8px;
    height:275px;
    overflow-x:auto ;
}

