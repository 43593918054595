.labelOne{
    padding: 2px;
    border-radius: 10px;
    background-color:#E6E6E6;
    height:125%;
    width: 100%;
}
.label-two {
    padding: 2px;
    border-radius: 10px;
    background-color:#E6E6E6;
    height:125%;
    width: 100%;
    display: flex;
    flex-direction: row;
}
@media (min-width: 320px) and (max-width: 425px) {
    .label-two {
        flex-direction: column;
    }
}
.course-name-label{
    padding: 2px;
    border-radius: 10px;
    background-color:#E6E6E6;
    height:125%;
    width: 100%;
    color: #726AA5;
}
.heading{
    margin-left: 8px;
    margin-bottom: 15px;
}
#para1{
    font-size:14px;
    color: #959292;
    margin-left: 10px;
    margin-top: 10px;
}
#para2{
    font-size:14px;
    color: #726AA5;
    margin-left: 10px;
    margin-top: 10px;
}
#para3{
    color: #726AA5;
    text-align: center;
    font-size: 1.2rem;
}
#para4{
    color: #959292;
    text-align: center;
    font-size: 1rem; 
}
#labelTwo{
    padding: 2px;
    border-radius: 10px;
    background-color:#ECF2FF;
    height:100%;
    width: 100%;
    
}

.labelAmount{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 10px;
    background-color:#E6E6E6;
    height:48px;
    width: 100%;
}