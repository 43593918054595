.btn-color {
  color: azure !important;
}

.HeaderNavbar-scroll {
  overflow-x: auto !important;
  white-space: nowrap !important;
}

@media (max-width: 740px) {
  .headerNavbar-button button {
    font-size: 10px !important;
  }
}
