.loading-container {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
}

.loader {
    width: 500px;
}
