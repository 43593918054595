
.react-tabs__tab--selected {
    background: #fff;
    border-color: #48b050;
    color: black;
    border-radius: 10px 10px 0 0;
}
.react-tabs__tab-list {
    border-bottom: 1px solid #48b050;
    margin: 0 0 10px;
    padding: 0;
}

.content {
    box-shadow: rgba(0, 0, 0, 0.17) 0px 5px 15px;
    padding: 7px;
    border-radius: 8px;
    background-color: #faf9f9;
}

.swal2-container{
    z-index: 10000;
}