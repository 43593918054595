.PaymentTableCard-container {
    display: flex;
    align-items: center;
    padding: 2%;
  }

  .PaymentTableCard-column2{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PaymentTableCard-column1{
    margin-right: 5%;
    border-left: 1px solid #B5B9C1;
    height: 100%;
  }

  .PaymentTableCard-column{
    flex-basis: 50%;
  }
  
  #PaymentTableCard-title3{
    font-size: 16px;
  }

  .paymentTable-grey{
    color: #344767;
  }

.paymentTable-lGrey{
    color:#7C7C7C;
}

.paymentTable-green{
    color:#4BAE4F;
}

@media (max-width: 990px)  {
  .hide-on-md {
    display: none !important;
  }
}

@media (max-width: 987px) {
  .margin-on-md {
    margin-left: 2%;
  }
}