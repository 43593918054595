.plus-icon {
    color: #f3f3f3;
    background-color: #c333d5;
    border-radius: 50px;
}
.course-name{
    font-size: 17px;
}
.student-detail{
    font-size: 17px;
}
.course-card{
    margin-top: 1%;
    padding: 2%;
    border:1px solid #E1DEDE ;
    border-radius: 10px;
    cursor: pointer;
}
.checked {
    background-color: #d0d0d2;
}