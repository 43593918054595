.PaymentReceipt-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PaymentReceipt-date {
  /*margin-left: 90%;*/
  color: #808080;
  font-size: 12px;
}

.PaymentBill-number {
  color: #808080;
  font-size: 12px;
}

.PaymentReceipt-color {
  font-size: 12px;
  color: #808080;
  font-weight: bold;
}

.PaymentReceipt-color2 {
  font-size: 12px;
  color: #808080;
}



.PaymentReceipt-image {
 display: flex;
  align-items: center;
  justify-content: left;
}

.PaymentReceipt-hr {
  border: 1px solid #c0c0c0;
}

.PaymentReceipt-emailing {
  display: flex;
  justify-content: space-between;
}

.PaymentReceipt-button {
  background-color: #9470fd !important;
  color: white !important;
}

.PaymentReceipt-borderRadius {
  border-radius: 15px !important;
}
