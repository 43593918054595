.icon-add-color {
    color: #f3f3f3;
    background-color: #0cc92c;
    border-radius: 50px;
}
.icon-remove-color {
    color: #f3f3f3;
    background-color: #ff1933;
    border-radius: 50px;
}
.header-style{
    font-size: 17px;
    color:#232326;
    padding: 15px;
}
.accessor-style{
    font-size: 15px;
    color:#576574 ;
    margin-top: 10px;
}
.non-expand {
    background-color: #e5e5e5;
    padding-top: 10px;
}
.expand {
    margin-top: 10px;
}
