.card1{
    background-color: white;
    height: 100px;
    border-radius: 10px;
    display: flex;
}
.smallCard{
    height:50%;
    width: 22%;
    margin-left: 14px;
    margin-top: 10px;
    border-radius: 6px;
    background-color: #D3D3D3;
    opacity: 85%;
}
#image{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
}
#title100{
    font-size: 17px;
    margin-left: 12px;
    margin-top: 10px;
    color: white;
}
#title200{
    margin-left: 12px;
    color: white;
}
