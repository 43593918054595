.image {
    display: block;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-size: 14px;
    -webkit-tap-highlight-color: transparent
}
.file-input {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}
.input-style {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}
.selected-image {
    width: 200px;
    height: 200px;
}

.image-upload{
    margin:0 auto;
}