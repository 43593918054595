.payment-text {
  font-size: larger;
  font-weight: bolder;
  color: #4bae4f;
}

.payment-hr {
  margin-bottom: 10px !important;
  margin-left: 35%;
  margin-right: 25%;
}

@media (max-width: 991px) {
  .hr-hide {
    display: none !important;
  }
}

.Payment-name {
  background-color: #e0e0e0 !important;
  font-size: 15px !important;
  box-shadow: 0px 4px 4px rgba(177, 174, 174, 0.844) !important;
  color: #4bae4f !important;
  font-weight: bolder !important;
  width: 100%;
}

.payment-button {
  background-color: #5EB562 !important;
  color: white !important;
}
