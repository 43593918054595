.search-bar {
    margin-left: 15px;
    margin-bottom: 10px;
    padding-left: 6px;
    height: 35px;
    background-color: #efefef;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    width: 200px;
}
 