.popper {
  width: 53px;
  height: 25px;
  padding: 3px;
  border-radius: 1.5rem;
  box-sizing: border-box;
  background-color: #59b15d;
  font-size: 11px;
  color: antiquewhite;
  font-weight: 500;
  text-align: center;
  position: absolute;
  border: none;
}
