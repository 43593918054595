#title2 {
  color: #5eb562;
  margin-left: 15px;

  font-size: 13px;
  font-weight: bold;
}

.rightNavCard {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  width: 100%;
  margin: 0 auto;
}

.rightNavFlex-container {
  display: flex;
  align-items: center;
  margin-top: 3%;
  margin-bottom: 3%;
}

.rightNavSmall-column {
  padding-left: 5%;
  margin-right: 5%;
  color: #d1d1d1;
}
.rightNavSmall-column1 {
  margin-right: 5%;
  border-left: 1px solid #5eb562;
  height: 100%;
}
.rightNavSmall-column2 {
  margin-right: 5%;
  margin-left: 5%;
}

.rightNavBig-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
