.image-box{
    width: 200px;
    margin-bottom: 10px;
    border: 1px solid #000;
    padding: 1px;
}
.upload-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
}
.upload-box:hover {
    border-color: #2196f3;
}
.input-image{
    display: none;
}