.my-class1{
    display: flex;
    justify-content: space-around;
}
.button{
    margin-left: 30px;
}
.invalid-image{
    display: flex;
    width: 100%;
    justify-content: left;
}