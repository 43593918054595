.assigned-course {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #e6e6e6;
  height: 3.1rem;
  width: 100%;
}
.topic {
  font-weight: revert;
  font-size: 25px;
  padding-top: 5px;
}
.unavailable-course {
  padding-top: 2px;
  color: #ff1933;
}
