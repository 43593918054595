
.grid1{   
    box-shadow: rgba(0, 0, 0, 0.17) 0px 5px 15px;
    padding: 7px;
    border-radius: 8px;
    background-color: #faf9f9;

}
/* .grid2{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 8px;
} */
/* .grid-container {
    display: grid;
} */
/* .grid-item {
    text-align: center;
} */
#headerTitle1{
    font-size: 11px;
}
#headerTitle2{
    font-size: 15px;
    margin-top: 8px;
}
#header{
    font-size: 16px;
    margin-left: 14px;
    margin-top: 5px;
}
.background{
    background-image: linear-gradient(90deg, #253BFF 0%, #ABB3FF 99.93%);
    border-radius: 14px;
}

