.my-class1{
    display: flex;
    justify-content: space-around;
   
}
.button{
    margin-left: 30px;
}

.Course_button-icon{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}