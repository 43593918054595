.course-assign-card{
    background-color: #E6E6E6;
    border-radius: 10px;
    margin-top: 2%;
    padding: 2%;
    font-size: 17px;
    display: flex;
    justify-content: space-between;
}
.not-select{
    color: #E41B2D;
}
.is-selected{
    color:#726AA5;
}
.set-assign-course{
    color: #f3f3f3;
    background-color: #0cc92c;
    border-radius: 50px;
}
.cancel-assign-course{
    color: #f3f3f3;
    background-color: #ff1933;
    border-radius: 50px;
}