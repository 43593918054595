.paymentCard-value {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30%;
  font-size: 30px;
}

.paymentCard-hr {
  margin-left: 75%;
  border-left: 2px solid #5eb562;
  height: 100%;
}

.PaymentCard-flex {
  display: flex;
  flex-wrap: wrap;
  background-color: #f4f4f4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  justify-content: space-around;

  padding-left: 2%;
}

.PaymentName-icon {
  display: flex;
  font-size: 14px;
}

.PaymentName-iconcheck {
  justify-content: center;
  align-items: center;
}

.grid-p {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 30px;
}

.Payment-vertical-line {
  border-left: 2px solid #cacaca;
  height: 100px;
  margin-top: 1%;
  margin-bottom: 1%;
}
.Payment-vertical-line2 {
  border-left: 2px solid #cacaca;
  height: 100px;
  margin-top: 1%;
  margin-bottom: 1%;
}

@media (max-width: 1489px) {
  .Payment-vertical-line2 {
    display: none;
  }
}

@media (max-width: 986px) {
  .Payment-vertical-line {
    display: none;
  }
}
