.heading{
    margin-left: 8px;
    margin-bottom: 15px;
}
.social_icon{
    width: 20px;
    height: 20px;
    border-radius: 100%;
}
.socialIconContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.link-box{
    border: 1px solid #C7D3FF;
    border-radius: 10px;
    align-items: center;
    flex-direction: row;
    color:#9F9F9F;
    font-size: 17px;
    padding-left: 6px;
}
.btnCopy{
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgUploder{
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnContainer{
    display: flex;
    justify-content: right;
}

