.RightBarButton{
    border-radius: 40% !important;
    width: 40px;
    height:40px;
    padding: 0 !important;
    min-width: 0 !important;
    right: 3px !important;
  
}

.RightNav-hr {

   border: none;
    border-top: 1px solid #F0F0F0;
    margin-top: 2%;
    margin-bottom: 3%;
}


.sideComponent{
    border: 2px black solid;
    border-radius: 15px;
}



.RightNavbar-image{
    margin-top: 10%;
}
.rightBar-name{
    background-color: #F4F4F4 !important;
    font-size: 15px !important;
    box-shadow: 0px 4px 4px rgba(222, 222, 222, 0.844) !important;
    margin-bottom: 14% !important;
    width:100% !important;
}